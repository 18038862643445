
export function getUrlParams() {
  let url = window.location.href;
  var urlsplit = url.split('?') //切出？后的参数部分
  var par = urlsplit[1].split('&')//切出&分割的参数
  var obj = {}
  for(let i = 0; i < par.length; i++){
    var p = par[i].split('='); //切出=分割的参数
    (obj as any)[p[0]] = p[1];
  }
  return obj
}
/**
 * 获取视频时长 传入url
 * @param {*} url 
 * @return {*}
 */
export function getDuration(url: string){
  return new Promise((resolve, reject) => {
      let videoDom = document.createElement("audio");
      videoDom.src = url;
      videoDom.addEventListener('canplay',function(){
          resolve(Number(videoDom.duration));
      });
  })
}

/**
 *  获取图片大小
 * @param {*} url 
 * @return {*}
 */
export function getImgSize(url: string) {
  return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = function() {
        let w = img.width;
        let h = img.height;
        resolve({
          w,
          h
        });
      }
      img.src = url;
   })
}

/**
 *  file转base64
 * @param {*} url 
 * @return {*}
 */
export function file2Base64(file: {result: string, [propName: string]: any}) {
  return new Promise((resolve, reject) => {
    let fileReader = new FileReader();
    fileReader.readAsDataURL(file as any);
    fileReader.onload = function () {
      resolve(fileReader)
    }
  })
}
/**
 *  base64转file
 * @param {*} url 
 * @return {*}
 */
export function dataURLtoFile(urlData: string) {//将base64转换为文件
  // var arr = dataurl.split(','),
  //     mime = (arr[0] as any).match(/:(.*?);/)[1],
  //     bstr = atob(arr[1]), 
  //     n = bstr.length, 
  //     u8arr = new Uint8Array(n);
  //     while(n--){
  //         u8arr[n] = bstr.charCodeAt(n);
  //     }
  // return new File([u8arr], filename, {type:mime});
  var bytes=window.atob(urlData.split(',')[1]);        //去掉url的头，并转换为byte  

    //处理异常,将ascii码小于0的转换为大于0  
    var ab = new ArrayBuffer(bytes.length);  
    var ia = new Uint8Array(ab);  
    for (var i = 0; i < bytes.length; i++) {  
        ia[i] = bytes.charCodeAt(i);  
    }  

    return new Blob( [ab] , {type : 'image/png'}); 
}

/**
 *  base64转blob
 * @param {*} base64Str 
 * @return {*}
 */
export function dataURLtoBlob(base64Str: string) {
  var arr = base64Str.split(','),
  mime = (arr[0] as any).match(/:(.*?);/)[1],
  bstr = atob(arr[1]),
  n = bstr.length,
  u8arr = new Uint8Array(n);
  while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], {
      type: mime
  });
}
/**
 *  blog转binary
 * @param {*} base64Str 
 * @return {*}
 */
export function blobToUint8Array(b: any) {
  var uri = URL.createObjectURL(b),
    xhr = new XMLHttpRequest(),
    i,
    ui8;
  xhr.open('GET', uri, false);
  xhr.send();
  URL.revokeObjectURL(uri);
  ui8 = new Uint8Array(xhr.response.length);
  for (i = 0; i < xhr.response.length; ++i) {
      ui8[i] = xhr.response.charCodeAt(i);
  }
  return ui8;
}
/**
 * 随机生成字符串
 * @param {*} base64Str 
 * @return {*}
 */
export function randomWord(randomFlag:Boolean, min:number, max:number){
  var str = "",
      range = min,
      arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

  // 随机产生
  if(randomFlag){
      range = Math.round(Math.random() * (max-min)) + min;
  }
  for(var i=0; i<range; i++){
      let pos = Math.round(Math.random() * (arr.length-1));
      str += arr[pos];
  }
  return str;
}

// 检测pc还是移动设备
export function isPC(){  
  var userAgentInfo = navigator.userAgent;
  var Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod");  
  var flag = true;  
  for (var v = 0; v < Agents.length; v++) {  
      if (userAgentInfo.indexOf(Agents[v]) > 0) { flag = false; break; }  
  }  
  return flag;  
}
