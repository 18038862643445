







































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import { Dictionary } from 'vue-router/types/router'
import { Form as ElForm, Input } from 'element-ui'
import { UserModule } from '@/store/modules/user'
import { isValidUsername } from '@/utils/validate'
import { login } from '@/api/users'
import { getUrlParams } from '@/utils'
import { getToken, setToken, removeToken } from '@/utils/cookies'


@Component({
  name: 'Login'
})
export default class extends Vue {
  private validateUsername = (rule: any, value: string, callback: Function) => {
    if (!isValidUsername(value)) {
      callback(new Error('Please enter the correct user name'))
    } else {
      callback()
    }
  }

  private validatePassword = (rule: any, value: string, callback: Function) => {
    if (value.length < 6) {
      callback(new Error('The password can not be less than 6 digits'))
    } else {
      callback()
    }
  }

  private loginForm = {
    username: 'admin',
    password: '111111'
  }

  private pageLoading = false;

  private loginRules = {
    username: [{ validator: this.validateUsername, trigger: 'blur' }],
    password: [{ validator: this.validatePassword, trigger: 'blur' }]
  }

  private passwordType = 'password'
  private loading = false
  private showDialog = false
  private redirect?: string
  private otherQuery: Dictionary<string> = {}

  public created(): void {
    let code = (getUrlParams() as any).code;
    if(code) {
      this.pageLoading = true;
      this.loginHandle(code);
    }
  }
  @Watch('$route', { immediate: true })
  private onRouteChange(route: Route) {
    // TODO: remove the "as Dictionary<string>" hack after v4 release for vue-router
    // See https://github.com/vuejs/vue-router/pull/2050 for details
    const query = route.query as Dictionary<string>
    if (query) {
      this.redirect = query.redirect
      this.otherQuery = this.getOtherQuery(query)
    }
  }

  mounted() {
    if (this.loginForm.username === '') {
      (this.$refs.username as Input).focus()
    } else if (this.loginForm.password === '') {
      (this.$refs.password as Input).focus()
    }
  }

  private showPwd() {
    if (this.passwordType === 'password') {
      this.passwordType = ''
    } else {
      this.passwordType = 'password'
    }
    this.$nextTick(() => {
      (this.$refs.password as Input).focus()
    })
  }

  private handleLogin() {
    this.pageLoading = true;
    // 模拟环境登陆
    if(window.location.host === 'localhost:8080'){
      setToken((process.env as any).VUE_APP_TOKEN)
      localStorage.setItem('avatar', 'https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eq1fuiaf4FibGobKmSAqDIqAF6mHamb8IqVowzia0USOtwy7zPO8W3vNflv6EgePMm73Qulygl5ibgXPA/132?imageView2/1/w/80/h/80')
      this.$router.push({
        path: this.redirect || '/',
        query: this.otherQuery
      })
    }else{
      removeToken();
      window.open(`https://open.weixin.qq.com/connect/qrconnect?appid=${process.env.VUE_APP_WX_APPID}&redirect_uri=${window.location.origin}/login&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect`)
    }
  }
  private loginHandle(code: string) {
    login({
      code
    }).then(async (res: any)=> {
      console.log(res);
      setToken(res.data.token)
      localStorage.setItem('avatar', res.data.avatar_url)
      this.pageLoading = false;
      this.$router.push({
        path: this.redirect || '/',
        query: this.otherQuery
      })
    })
  }

  private getOtherQuery(query: Dictionary<string>) {
    return Object.keys(query).reduce((acc, cur) => {
      if (cur !== 'redirect') {
        acc[cur] = query[cur]
      }
      return acc
    }, {} as Dictionary<string>)
  }
}
